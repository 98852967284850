import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import Svg from 'react-inlinesvg';
import Lottie from 'react-lottie';
import sendDataLayer from '../../../utils/sendDataLayer';
import 'react-input-range/lib/css/index.css';
import styles from '../../../scss/components/ability.module.scss';
import animationData from '../../../animationData/press-up.json';
import QuestionMark from '../../../images/svgs/icon_question_mark.svg';
import outdoorBg from '../../../images/svgs/background/outdoor.svg';
import indoorBg from '../../../images/svgs/background/indoor.svg';
import spaceBg from '../../../images/svgs/background/space.svg';

const AbilityIndex = ({ cms, setStep, goBackStep }) => {
  const [duration, setDuration] = useState(14);
  const [level, setLevel] = useState(2);
  const [tooltip, openTooltip] = useState(false);
  const location = localStorage.getItem('location');
  const [speed, setSpeed] = useState(1);

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const openTooltipModal = () => {
    if (!tooltip) {
      openTooltip(true);
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Open tooltip');
    } else {
      openTooltip(false);
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Close tooltip');
    }
  };

  const onLevelChange = level => {
    setLevel(level);
    localStorage.setItem('level', level);
    switch (level) {
      case 1:
        localStorage.setItem('levelString', 'easy');
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'easy');
        setSpeed(1);
        return;
      case 2:
        localStorage.setItem('levelString', 'medium');
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'medium');

        setSpeed(1.3);
        return;
      default:
        localStorage.setItem('levelString', 'intense');
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'intense');
        setSpeed(1.5);
    }
  };

  const onDurationChange = duration => {
    setDuration(duration);
    localStorage.setItem('duration', duration);
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${duration} mins`);
  };

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Your Ability', '/');
    setDuration(localStorage.getItem('duration'));
    setLevel(localStorage.getItem('level'));
  }, []);

  return (
    <div className={`${styles.ability} ability`}>
      <h1 className={`${styles.ability__title} color--tangerine text--center`}>
        {cms.level.title}
      </h1>
      <div className={`${styles.ability__wrapper} push-v--desktop--x-small`}>
        <div className={`${styles.ability__animationWrapper} push-v--mobile--x-small`}>
          {location === 'inside' && <Svg className={styles.ability__bg} src={indoorBg} />}
          {location === 'outside' && <Svg className={styles.ability__bg} src={outdoorBg} />}
          {location === 'outer space' && <Svg className={styles.ability__bg} src={spaceBg} />}
          <div className={`${styles.ability__lottieAnimation} lottie-wrapper`}>
            <Lottie options={animationOptions} speed={speed} useSubFrames={false} />
          </div>
        </div>

        <div className={styles.ability__lockup}>
          <div className={`${styles.ability__tooltip} ${tooltip ? 'tooltip-open' : ''}`}>
            <Svg
              src={QuestionMark}
              className={styles.ability__tooltipIcon}
              onClick={openTooltipModal}
            />
            <div className={`${styles.ability__tooltipModal}`}>
              <p className={`${styles.ability__tooltipText} text--light text--center color--white`}>
                {cms.level.tooltip}
              </p>
            </div>
          </div>

          <div className={`${styles.ability__rangeWrapper} level`}>
            <InputRange
              maxValue={3}
              minValue={1}
              step={1}
              value={parseInt(level, 10)}
              onChange={onLevelChange}
            />
          </div>
          <div className={`${styles.ability__rangeWrapper} duration`}>
            <InputRange
              maxValue={21}
              minValue={7}
              value={parseInt(duration, 10)}
              onChange={onDurationChange}
              step={7}
              formatLabel={duration => `${duration} mins`}
            />
          </div>
        </div>
      </div>
      <div className={styles.ability__buttonsWrapper}>
        <button
          type="button"
          className={`${styles.ability__nextButton} button button--dark button--large ${
            localStorage.getItem('duration') && localStorage.getItem('level') ? '' : 'disabled'
          } push-v--tablet--x-small`}
          onClick={() => {
            setStep();
            sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Next Button Your Ability');
          }}
        >
          Next
        </button>
        <button
          type="button"
          className={`${styles.ability__goBackButton} cta cta--tangerine`}
          onClick={() => {
            goBackStep();
            sendDataLayer(
              'gtm.event',
              'Interaction',
              'Button Click',
              'Go back Button Your Ability',
            );
          }}
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default AbilityIndex;
