import React from 'react';
// import PropTypes from 'prop-types';
// import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import LandingIndex from '../components/Landing/index';
import cms from '../cms.json';

const Index = () => (
  <Layout>
    <SEO title="Spotify HIIT" pageUrl="/" twitterUsername="TwitterUsername" />
    <LandingIndex cms={cms} />
  </Layout>
);

export default Index;
