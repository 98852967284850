import React, { useState, useEffect } from 'react';
import Swiper from 'swiper';
import Svg from 'react-inlinesvg';
import sendDataLayer from '../../../utils/sendDataLayer';

import 'swiper/swiper-bundle.min.css';
import styles from '../../../scss/components/location.module.scss';
import outdoorBg from '../../../images/svgs/background/outdoor.svg';
import outdoorBgColour from '../../../images/svgs/background/outdoor-c.svg';
import indoorBg from '../../../images/svgs/background/indoor.svg';
import indoorBgColour from '../../../images/svgs/background/indoor-c.svg';
import spaceBg from '../../../images/svgs/background/space.svg';
import spaceBgColour from '../../../images/svgs/background/space-c.svg';

const LocationIndex = ({ cms, setStep }) => {
  const [locationAssigned, setLocationAssigned] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const locationButtons = document.querySelectorAll('#js-location-button');
  const locationBackground = document.querySelectorAll('#js-location-background');
  const locationName = localStorage.getItem('location');
  const swiper = document.querySelector('#location-swiper');

  const chooseLocation = e => {
    const element = e.currentTarget;
    const elementButton = e.currentTarget.querySelector('#js-location-button');
    const elementBackground = e.currentTarget.querySelector('#js-location-background');
    const { location } = element.dataset;

    locationButtons.forEach(button => {
      button.classList.remove('active');
    });

    locationBackground.forEach(background => {
      background.classList.remove('active');
    });

    elementButton.classList.add('active');
    elementBackground.classList.add('active');
    localStorage.setItem('location', location);
    setLocationAssigned(true);
  };

  useEffect(() => {
    const locationSwiper = new Swiper('.swiper-container', {
      spaceBetween: 10,
      slidesPerView: 1.7,
      centeredSlides: true,
      initialSlide: 1,
      grabCursor: true,
      noSwiping: false,
      allowSlidePrev: true,
      allowSlideNext: true,
      breakpoints: {
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
          noSwiping: true,
          allowSlidePrev: false,
          allowSlideNext: false,
        },
        800: {
          slidesPerView: 1.7,
          noSwiping: false,
          allowSlidePrev: true,
          allowSlideNext: true,
        },
      },
    });

    setSwiperInstance(locationSwiper);
  }, []);

  const updateSlides = () => {
    if (swiperInstance) {
      if (window.orientation === 90 || window.orientation === -90) {
        swiperInstance.params.slidesPerView = 3;
        swiperInstance.update();
      } else {
        swiperInstance.params.slidesPerView = 1.7;
        swiperInstance.update();
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1025) {
      updateSlides();
      window.addEventListener('resize', () => {
        updateSlides();
      });
    }
  }, [swiperInstance]);

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Pick a place', '/');
  }, []);

  return (
    <div className={`${styles.location} wrapper`}>
      <h1
        className={`${styles.location__title} color--tangerine text--center push-v--mobile--x-small`}
      >
        {cms.location.title}
      </h1>
      <p
        className={`${styles.location__subtitle} text--center color--tangerine push-v--mobile--small push-v--tablet--large push-v--desktop--small`}
      >
        {cms.location.subtitle}
      </p>
      <div className={`${styles.location__swiperContainer} swiper-container`} id="location-swiper">
        <div
          className={`${styles.location__swiperWrapper} swiper-wrapper push-v--mobile--large push-v--tablet--x-large`}
        >
          <button
            type="button"
            data-location="inside"
            className={`${styles.location__location} swiper-slide`}
            onClick={e => {
              chooseLocation(e);
              sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Inside');
            }}
          >
            <div
              className={`${styles.location__background} ${
                locationName === 'inside' ? 'active' : ''
              } push-v--mobile--x-small`}
              id="js-location-background"
            >
              <Svg className={`${styles.location__bg} location-bg`} src={indoorBg} />
              <Svg
                className={`${styles.location__bgColor} location-bg-color`}
                src={indoorBgColour}
              />
            </div>

            <div
              id="js-location-button"
              className={`${styles.location__locationButton} ${
                locationName === 'inside' ? 'active' : ''
              } button button--dark button--small`}
            >
              Inside
            </div>
          </button>
          <button
            type="button"
            data-location="outside"
            className={`${styles.location__location} swiper-slide`}
            onClick={e => {
              chooseLocation(e);
              sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Outside');
            }}
          >
            <div
              className={`${styles.location__background} ${
                locationName === 'outside' ? 'active' : ''
              } push-v--mobile--x-small`}
              id="js-location-background"
            >
              <Svg className={`${styles.location__bg} location-bg`} src={outdoorBg} />
              <Svg
                className={`${styles.location__bgColor} location-bg-color`}
                src={outdoorBgColour}
              />
            </div>
            <div
              id="js-location-button"
              className={`${styles.location__locationButton} ${
                locationName === 'outside' ? 'active' : ''
              } button button--dark button--small`}
            >
              Outside
            </div>
          </button>
          <button
            type="button"
            data-location="outer space"
            className={`${styles.location__location} swiper-slide`}
            onClick={e => {
              chooseLocation(e);
              sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Outer Space');
            }}
          >
            <div
              className={`${styles.location__background} ${
                locationName === 'outer space' ? 'active' : ''
              } push-v--mobile--x-small`}
              id="js-location-background"
            >
              <Svg className={`${styles.location__bg} location-bg`} src={spaceBg} />
              <Svg
                className={`${styles.location__bgColor} location-bg-color`}
                src={spaceBgColour}
              />
            </div>

            <div
              id="js-location-button"
              className={`${styles.location__locationButton} ${
                locationName === 'outer space' ? 'active' : ''
              } button button--dark button--small`}
            >
              Outer Space
            </div>
          </button>
        </div>
      </div>
      <div className={`${styles.location__buttonWrapper} ${locationName ? '' : 'disabled'}`}>
        <button
          type="button"
          className={`${styles.location__button}  button button--dark button--large`}
          onClick={() => {
            setStep();
            sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Next Button Pick a place');
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LocationIndex;
