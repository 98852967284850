/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import Svg from 'react-inlinesvg';
import Swiper from 'swiper';
import { Howl } from 'howler';
import sendDataLayer from '../../../utils/sendDataLayer';

import 'swiper/swiper-bundle.min.css';
import where from '../../../images/where.jpg';
import reciepts from '../../../images/receipts.jpg';
import threeShots from '../../../images/3_shots.jpg';
import dyers from '../../../images/dyers.jpg';
import Play from '../../../images/svgs/icon_play.svg';
import Pause from '../../../images/svgs/icon_pause.svg';
import styles from '../../../scss/components/coach.module.scss';

const CoachIndex = ({ cms, setStep, goBackStep }) => {
  const [coachAssigned, setcoachAssigned] = useState(false);
  const [daniPlaying, setDaniPlaying] = useState(false);
  const [tazerPlaying, setTazerPlaying] = useState(false);
  const [tollyPlaying, setTollyPlaying] = useState(false);
  const [niallPlaying, setNiallPlaying] = useState(false);
  const coachButtons = document.querySelectorAll('#js-coach-button');
  const coach = localStorage.getItem('coach');
  let coachSample = null;

  const chooseCoach = e => {
    const element = e.currentTarget;
    const { coach } = element.dataset;

    coachButtons.forEach(button => {
      button.classList.remove('active');
    });

    element.classList.add('active');
    localStorage.setItem('coach', coach);
    setcoachAssigned(true);
  };

  const setKeyState = key => {
    switch (key) {
      case '1':
        setDaniPlaying(true);
        break;
      case '2':
        setTazerPlaying(true);
        break;
      case '3':
        setTollyPlaying(true);
        break;
      case '4':
        setNiallPlaying(true);
        break;
      default:
        console.log('no match');
    }
  };

  const unsetKeyState = key => {
    switch (key) {
      case '1':
        setDaniPlaying(false);
        break;
      case '2':
        setTazerPlaying(false);
        break;
      case '3':
        setTollyPlaying(false);
        break;
      case '4':
        setNiallPlaying(false);
        break;
      default:
        console.log('no match');
    }
  };

  const playSample = key => {
    if (coachSample != null) {
      coachSample.stop();
      coachSample.unload();
      coachSample = null;
    } else {
      window.Howler.unload();
    }
    coachSample = new Howl({
      preload: false,
      src: [`https://hiit-audios.storage.googleapis.com/${key}/sample.mp3`],
      onstop() {
        unsetKeyState(key);
      },
      onend() {
        unsetKeyState(key);
      },
    });
    coachSample.load();
    coachSample.play();
  };

  const selectSample = e => {
    const el = e.currentTarget;
    const { key } = el.dataset;

    setKeyState(key);
    playSample(key);
  };

  const initSwiper = () => {
    const coachSwiper = new Swiper('.swiper-container', {
      spaceBetween: 10,
      slidesPerView: 1.7,
      centeredSlides: true,
      initialSlide: 1,
      grabCursor: true,
      noSwiping: false,
      allowSlidePrev: true,
      allowSlideNext: true,
      observer: true,
      breakpoints: {
        600: {
          spaceBetween: 10,
          slidesPerView: 4,
          noSwiping: true,
          allowSlidePrev: false,
          allowSlideNext: false,
          centeredSlides: false,
          initialSlide: 0,
          grabCursor: false,
        },
      },
    });
  };

  useEffect(() => {
    initSwiper();
    sendDataLayer('gtm.event', 'Pageview', 'Your Coach', '/');
  }, []);

  return (
    <div className={`${styles.coach} coach`}>
      <h1
        className={`${styles.coach__title} color--tangerine text--center push-v--mobile--x-small`}
      >
        {cms.coach.title}
      </h1>
      <p
        className={`${styles.coach__subtitle} text--center color--tangerine push-v--mobile--small push-v--tablet--large`}
      >
        {cms.coach.subtitle}
      </p>
      <div
        className={`${styles.coach__swiperContainer} swiper-container push-v--desktop--large`}
        id="coach-swiper"
      >
        <div
          className={`${styles.coach__swiperWrapper} swiper-wrapper push-v--mobile--large push-v--tablet--huge push-v--desktop--xx-small`}
        >
          <div className={`${styles.coach__coachContainer} swiper-slide`}>
            <button
              type="button"
              data-coach="1"
              onClick={e => {
                chooseCoach(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Dani Dyer Coach');
              }}
              className={`${styles.coach__coach} ${coach === '1' ? 'active' : ''} ${
                coach === '' ? 'active' : ''
              }`}
              id="js-coach-button"
            >
              <p className={`${styles.coach__cta} cta cta--tangerine  push-v--mobile--x-small`}>
                <span className="text--bold">Dani Dyer</span> from
              </p>
              <img
                className={`${styles.coach__image} push-v--mobile--x-small`}
                src={dyers}
                alt="podcast cover"
              />
            </button>
            <p
              onClick={e => {
                selectSample(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Play Dani Sample');
              }}
              data-key="1"
              className={`${styles.coach__play} ${
                daniPlaying ? 'unselectable' : ''
              } color--tangerine`}
            >
              Sample
              {!daniPlaying && <Svg src={Play} />}
              {daniPlaying && <Svg src={Pause} />}
            </p>
          </div>

          <div className={`${styles.coach__coachContainer} swiper-slide`}>
            <button
              type="button"
              data-coach="2"
              onClick={e => {
                chooseCoach(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Tazer Coach');
              }}
              className={`${styles.coach__coach} ${coach === '2' ? 'active' : ''} ${
                coach === '' ? 'active' : ''
              }`}
              id="js-coach-button"
            >
              <p className={`${styles.coach__cta} cta cta--tangerine  push-v--mobile--x-small`}>
                <span className="text--bold">Tazer</span> from
              </p>
              <img
                className={`${styles.coach__image} push-v--mobile--x-small`}
                src={threeShots}
                alt="podcast cover"
              />
            </button>
            <p
              onClick={e => {
                selectSample(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Play Tazer Sample');
              }}
              data-key="2"
              className={`${styles.coach__play} ${
                tazerPlaying ? 'unselectable' : ''
              } color--tangerine`}
            >
              Sample
              {!tazerPlaying && <Svg src={Play} />}
              {tazerPlaying && <Svg src={Pause} />}
            </p>
          </div>

          <div className={`${styles.coach__coachContainer} swiper-slide`}>
            <button
              type="button"
              data-coach="3"
              onClick={e => {
                chooseCoach(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Tolly Coach');
              }}
              className={`${styles.coach__coach} ${coach === '3' ? 'active' : ''} ${
                coach === '' ? 'active' : ''
              }`}
              id="js-coach-button"
            >
              <p className={`${styles.coach__cta} cta cta--tangerine  push-v--mobile--x-small`}>
                <span className="text--bold">Tolly T</span> from
              </p>
              <img
                className={`${styles.coach__image} push-v--mobile--x-small`}
                src={reciepts}
                alt="podcast cover"
              />
            </button>
            <p
              onClick={e => {
                selectSample(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Play Tolly Sample');
              }}
              data-key="3"
              className={`${styles.coach__play} ${
                tollyPlaying ? 'unselectable' : ''
              } color--tangerine`}
            >
              Sample
              {!tollyPlaying && <Svg src={Play} />}
              {tollyPlaying && <Svg src={Pause} />}
            </p>
          </div>

          <div className={`${styles.coach__coachContainer} swiper-slide`}>
            <button
              type="button"
              data-coach="4"
              onClick={e => {
                chooseCoach(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Niall Coach');
              }}
              className={`${styles.coach__coach} ${coach === '4' ? 'active' : ''} ${
                coach === '' ? 'active' : ''
              }`}
              id="js-coach-button"
            >
              <p className={`${styles.coach__cta} cta cta--tangerine  push-v--mobile--x-small`}>
                <span className="text--bold">Niall</span> from
              </p>
              <img
                className={`${styles.coach__image} push-v--mobile--x-small`}
                src={where}
                alt="podcast cover"
              />
            </button>
            <p
              onClick={e => {
                selectSample(e);
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Play Niall Sample');
              }}
              data-key="4"
              className={`${styles.coach__play} ${
                niallPlaying ? 'unselectable' : ''
              } color--tangerine`}
            >
              Sample
              {!niallPlaying && <Svg src={Play} />}
              {niallPlaying && <Svg src={Pause} />}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.coach__buttonsWrapper}>
        <div
          className={`${localStorage.getItem('coach') ? '' : 'disabled'} push-v--tablet--x-small`}
        >
          <button
            type="button"
            className={`${styles.coach__nextButton}  button button--dark button--large`}
            onClick={() => {
              setStep();
              sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Next Button Your Coach');
            }}
          >
            Next
          </button>
        </div>
        <button
          type="button"
          className={`${styles.coach__goBackButton} cta cta--tangerine`}
          onClick={() => {
            goBackStep();
            sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Go back Button Your Coach');
          }}
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default CoachIndex;
