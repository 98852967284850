import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import Landing from './Steps/landing';
import Location from './Steps/location';
import Ability from './Steps/ability';
import Coach from './Steps/coach';
import Style from './Steps/style';

const LandingIndex = ({ cms }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const connect = () => {
    navigate('/connect');
  };

  useEffect(() => {
    if (localStorage.getItem('connectUrl') === 'true') {
      setCurrentStep(4);
    } else {
      localStorage.setItem('currentStep', 0);
      localStorage.setItem('location', '');
      localStorage.setItem('level', 2);
      localStorage.setItem('duration', 14);
      localStorage.setItem('coach', '');
      localStorage.setItem('genres', []);
      localStorage.setItem('levelString', 'medium');
      localStorage.setItem('connectUrl', 'false');
      localStorage.setItem('playlistUrl', '');
      localStorage.setItem('payoff', 'false');
    }
  }, []);

  const setStep = () => {
    window.scrollTo(0, 0);
    switch (currentStep) {
      case 0:
        setCurrentStep(1);
        localStorage.setItem('currentStep', 1);
        break;
      case 1:
        setCurrentStep(2);
        localStorage.setItem('currentStep', 2);
        break;
      case 2:
        setCurrentStep(3);
        localStorage.setItem('currentStep', 3);
        break;
      default:
        setCurrentStep(4);
        localStorage.setItem('currentStep', 4);
        break;
    }
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    switch (currentStep) {
      case 2:
        setCurrentStep(1);
        localStorage.setItem('currentStep', 1);
        break;
      case 3:
        setCurrentStep(2);
        localStorage.setItem('currentStep', 2);
        break;
      default:
        setCurrentStep(3);
        localStorage.setItem('currentStep', 3);
        break;
    }
  };

  return (
    <div className="landing">
      {currentStep == 0 ? (
        <Landing cms={cms} setStep={setStep} currentStep={currentStep} />
      ) : currentStep == 1 ? (
        <Location cms={cms} setStep={setStep} />
      ) : currentStep == 2 ? (
        <Ability cms={cms} setStep={setStep} goBackStep={goBackStep} />
      ) : currentStep == 3 ? (
        <Coach cms={cms} setStep={setStep} goBackStep={goBackStep} />
      ) : (
        <Style cms={cms} connect={connect} goBackStep={goBackStep} />
      )}
    </div>
  );
};

export default LandingIndex;
