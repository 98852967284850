import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { TweenMax, Sine } from 'gsap';
import styles from '../../../scss/components/style.module.scss';
import desktop from '../../../animationData/waves/wave-1-desktop.json';
import tablet from '../../../animationData/waves/wave-1-tablet.json';
import mobile from '../../../animationData/waves/wave-1-mobile.json';
import mobileLandscape from '../../../animationData/waves/wave-1-mobile-landscape.json';
import sendDataLayer from '../../../utils/sendDataLayer';

const StyleIndex = ({ cms, connect, goBackStep }) => {
  const [genres, setGenres] = useState([]);
  const [genre, setGenre] = useState(null);
  const [device, setDevice] = useState('desktop');

  const animationOptionsDesktop = {
    loop: true,
    autoplay: true,
    animationData: desktop,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsTablet = {
    loop: true,
    autoplay: true,
    animationData: tablet,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsMobile = {
    loop: true,
    autoplay: true,
    animationData: mobile,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsMobileLandscape = {
    loop: true,
    autoplay: true,
    animationData: mobileLandscape,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const createGenreButtons = async () => {
    setGenre(
      cms.style.styles.map((style, i) => (
        <button
          type="button"
          onClick={event => addGenre(event, style.name)}
          className={`${styles.style__genre} button button--dark button--large ${
            localStorage.getItem('genres').includes(style.id) ? 'active' : ''
          }`}
          data-genre={style.id}
          key={i}
        >
          {style.name}
        </button>
      )),
    );
  };

  const addGenre = (e, name) => {
    const style = e.target.dataset.genre;
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${name}`);

    if (genres.length < 4) {
      if (!genres.includes(style)) {
        setGenres([genres.push(style)]);
        localStorage.setItem('genres', genres);
        createGenreButtons();
      } else {
        setGenres([genres.splice(genres.indexOf(style), 1)]);
        localStorage.setItem('genres', genres);
        createGenreButtons();
      }
    } else if (genres.length === 4) {
      if (genres.includes(style)) {
        setGenres([genres.splice(genres.indexOf(style), 1)]);
        localStorage.setItem('genres', genres);
        createGenreButtons();
      }
    }
  };

  const deviceDetector = () => {
    if (window.innerWidth <= 600) {
      setDevice('mobile');
    } else if (
      window.innerHeight < window.innerWidth &&
      window.innerWidth < 900 &&
      window.innerHeight < 450
    ) {
      setDevice('mobile-landscape');
    } else if (window.innerWidth > 601 && window.innerWidth < 1025) {
      setDevice('tablet');
    } else {
      setDevice('desktop');
    }
  };

  useEffect(() => {
    createGenreButtons();
    localStorage.setItem('connectUrl', 'false');
  }, []);

  useEffect(() => {
    deviceDetector();
    window.addEventListener('resize', deviceDetector);

    return () => window.removeEventListener('resize', deviceDetector);
  });

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Your Style', '/');

    TweenMax.to('.style', 1, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0,
    });
  }, []);

  return (
    <div className={`${styles.style} style wrapper`}>
      <h1
        className={`${styles.style__title} color--tangerine text--center push-v--mobile--x-small`}
      >
        {cms.style.title}
      </h1>
      <p
        className={`${styles.style__subtitle} text--center color--tangerine push-v--mobile--small`}
      >
        {cms.style.subtitle}
      </p>
      <div className={`${styles.style__genres} push-v--mobile--x-large`}>{genre}</div>
      <div className={styles.style__buttonsWrapper}>
        <div className={localStorage.getItem('genres') ? '' : 'disabled'}>
          <button
            type="button"
            className={`${styles.style__nextButton} button button--light button--large`}
            onClick={() => {
              connect();
              sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Next Button Your Style');
            }}
          >
            Next
          </button>
        </div>
        <button
          type="button"
          className={`${styles.style__goBackButton} cta cta--green`}
          onClick={() => {
            goBackStep();
            sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Go back Button Your Style');
          }}
        >
          Go back
        </button>
      </div>
      <div className={styles.style__lottieAnimation}>
        {device === 'desktop' && <Lottie options={animationOptionsDesktop} />}
        {device === 'tablet' && <Lottie options={animationOptionsTablet} />}
        {device === 'mobile' && <Lottie options={animationOptionsMobile} />}
        {device === 'mobile-landscape' && <Lottie options={animationOptionsMobileLandscape} />}
      </div>
    </div>
  );
};

export default StyleIndex;
