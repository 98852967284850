import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Svg from 'react-inlinesvg';
import { navigate } from 'gatsby';
import { Sine, TweenMax } from 'gsap';
import sendDataLayer from '../../../utils/sendDataLayer';
import styles from '../../../scss/components/landing.module.scss';

import outdoorBg from '../../../images/svgs/background/outdoor.svg';
import indoorBg from '../../../images/svgs/background/indoor.svg';
import spaceBg from '../../../images/svgs/background/space.svg';
import PressUp from '../../../images/svgs/exercises/press_up.svg';
import SquatJump from '../../../images/svgs/exercises/jump_squats.svg';
import HighKnees from '../../../images/svgs/exercises/knee_up.svg';

const LandingIndex = ({ cms, setStep, location, currentStep }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [checked, setIsChecked] = useState(false);

  Modal.setAppElement('#___gatsby');

  const openModal = () => {
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Open exercise disclaimer Modal');

    setIsOpen(true);
  };
  const checkboxToggle = () => {
    if (checked) {
      setIsChecked(false);
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Exercise Disclaimer rejected');
    } else {
      setIsChecked(true);
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Exercise Disclaimer accepted');
    }
  };

  const closeModal = () => {
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Close modal');

    TweenMax.to('.modal--fade', 0.4, {
      opacity: 0,
      ease: Sine.easeInOut,
      onComplete: () => {
        setIsOpen(false);
      },
    });
  };

  const handleClick = (event, link, label) => {
    event.preventDefault();
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${label}`);
    window.open(`${link}`);
  };

  useEffect(() => {
    if (currentStep == 0 && localStorage.getItem('connectUrl') !== 'true') {
      sendDataLayer('gtm.event', 'Pageview', 'Pump up your workout', '/');
    }
  }, []);

  return (
    <div className={styles.landing}>
      <div className={styles.landing__wrapper}>
        <div className={`${styles.landing__image} ${styles.landing__image1}`}>
          <Svg src={indoorBg} className={styles.landing__bg} />
          <Svg src={PressUp} className={styles.landing__exercise} />
        </div>
        <div className={styles.landing__stepWrapper}>
          <h1
            className={`${styles.landing__title} color--tangerine text--center push-v--mobile--x-small`}
          >
            {cms.landing.title}
          </h1>
          <p
            className={`${styles.landing__subtitle} color--tangerine push-v--mobile--small push-v--tablet--large`}
          >
            {cms.landing.subtitle}
          </p>
          <div className={checked ? '' : 'disabled'}>
            <button
              type="button"
              className={`${styles.landing__button} button button--dark push-v--mobile--small push-v--tablet--large`}
              onClick={setStep}
              onClick={() => {
                setStep();
                sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Lets Hiit it');
              }}
            >
              {cms.landing.ctaText}
            </button>
          </div>
          <div className={`${styles.landing__checkbox} checkbox-wrapper text--center`}>
            <input type="checkbox" id="privacyTerms" name="privacyTerms" onClick={checkboxToggle} />
            <label htmlFor="privacyTerms" className="color--tangerine">
              I accept
              <a className="cta cta--tangerine cta--underline" onClick={openModal}>
                <span>{cms.landing.disclaimer}</span>
              </a>
              and
              <a
                className="cta cta--tangerine cta--underline"
                target="_blank"
                rel="noopener noreferrer"
                href="#"
                onClick={event =>
                  handleClick(event, cms.landing.privacy.url, cms.landing.privacy.label)
                }
              >
                <span>{cms.landing.privacy.label}</span>
              </a>
            </label>
          </div>
        </div>

        <div className={`${styles.landing__image} ${styles.landing__image2}`}>
          <Svg src={outdoorBg} className={styles.landing__bg} />
          <Svg src={HighKnees} className={styles.landing__exercise} />
        </div>
        <div className={`${styles.landing__image} ${styles.landing__image3}`}>
          <Svg src={spaceBg} className={styles.landing__bg} />
          <Svg src={SquatJump} className={styles.landing__exercise} />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Help Modal"
        className="modal modal--fade"
        overlayClassName="modal__overlay"
      >
        <button type="button" onClick={closeModal} className="modal__close-cta cta cta--tangerine">
          Close
        </button>

        <div className="modal__container">
          <p className="modal__title h3 push-v--mobile--small">Exercise disclaimer</p>
          <p className="modal__privacy text--light push-v--mobile--medium">
            Before starting your workout please read and accept our exercise disclaimer.
          </p>
          <p className="text--light push-v--mobile--x-small">
            This programme is provided by Spotify for entertainment purposes only and we are not
            qualified to express an opinion that you are fit to safely participate. You should stop
            immediately if you experience faintness, dizziness, pain or shortness of breath at any
            time while exercising. Do not participate in this programme if you are pregnant or under
            the influence of alcohol or non-prescription drugs. Before starting, please ensure you
            have enough space to carry out the exercises without injuring yourself or others. You
            should consult your doctor before starting this programme to decide if it is right for
            you, especially if you:
          </p>
          <p className="text--light push-v--mobile--x-small">
            (i) are currently taking medication; (ii) smoke; (iii) are obese; (iv) have an existing
            medical condition (including but not limited to heart conditions, high blood pressure,
            high cholesterol, asthma or if you have experienced chest pain or dizziness); or (v)
            have any health concerns relating to this programme.
          </p>
          <p className="text--light push-v--mobile--x-small">
            Do not start this programme if your doctor (or other health care professional) has
            advised against it. The exercises in this programme are not suitable for anyone under
            18. If you engage in this programme you do so at your own risk. Spotify will not be
            liable for any injuries which may occur as a result of the exercises included in this
            programme.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default LandingIndex;
